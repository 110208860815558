import styled from 'styled-components';
import { themeGet } from 'styled-system';

const PageOverview = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 25px;

  .overview-meta {
    display: block;
    text-align: center;
    width: 100%;
    margin: 5% 0;

    h3 {
      margin: 0;
      font-style: italic;
    }

    .post-count {
      font-size: 1.3rem;
      position: relative;
      font-family: 'Inter', sans-serif;
      margin-top: 25px;
      padding-top: 25px;
      &::before {
        content: '';
        width: 50px;
        height: 1px;
        background-color: ${themeGet('color.2')};
        position: absolute;
        left: calc(50% - 50px / 2);
        top: 0;
      }
    }
  }

  .masonry-grid {
    display: flex;
    margin-left: -16px;
    width: auto;
    padding-bottom: 2rem;
  }

  .masonry-grid_column {
    padding-left: 16px;
    background-clip: padding-box;
    .content-card {
      margin-bottom: 17px;

      h3 {
        font-size: 1.3rem;
      }
    }
  }
`;

export default PageOverview;
