import React from 'react';
import { graphql } from 'gatsby';
import Fade from 'react-reveal/Fade';
import Masonry from 'react-masonry-css';
import Layout from '../../layout';
import SEO from '../../components/Seo/Seo';
import ContentCard from '../../components/ContentCard/ContentCard';
import PageOverview from './journals.style';

const Journals = ({ data: { allWordpressWpJournal } }) => {
  const masonryColumns = {
    default: 4,
    1600: 3,
    700: 2,
    500: 1,
  };

  return (
    <Layout>
      <SEO title="All journals" />

      <PageOverview className="page-overview">
        <div className="overview-meta">
          <h3>Alle journals</h3>
          <div className="post-count">
            {allWordpressWpJournal.edges.length} resultaten
          </div>
        </div>

        <Masonry
          breakpointCols={masonryColumns}
          className="masonry-grid"
          columnClassName="masonry-grid_column"
        >
          {allWordpressWpJournal.edges.map(journal => (
            <Fade bottom key={journal.node.id}>
              <ContentCard
                path={journal.node.path}
                title={journal.node.title}
                image={journal.node.featured_media}
              />
            </Fade>
          ))}
        </Masonry>
      </PageOverview>
    </Layout>
  );
};

export default Journals;

export const pageQuery = graphql`
  {
    allWordpressWpJournal {
      edges {
        node {
          id
          title
          path
          status
          featured_media {
            alt_text
            localFile {
              childImageSharp {
                fluid(maxWidth: 400, maxHeight: 400) {
                  src
                  srcSet
                  srcSetWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
